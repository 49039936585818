<template>
  <div>
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title" @click="goBack">
          <i class="el-icon-arrow-left"></i><span>返回</span>
          <!-- <span class="backBtn" @click="goBack">返回</span> -->
        </span>
        <!-- <el-button class="refundBtn" type="text" @click="refund">申请退款</el-button> -->
      </div>
      <div class="orderContent">
        <div class="orderDetailTitle">订单详情</div>
        <el-form ref="form" :model="form" label-width="130px">
          <el-form-item label="订单编号：">
            <div>{{ detail.orderId }}</div>
          </el-form-item>
          <el-form-item label="订单状态：">
            <div>{{ detail.orderStatus }}</div>
          </el-form-item>
          <el-form-item label="订单明细：">
            <el-table
              :data="detail.list"
              border
              :summary-method="getSummaries"
              show-summary
              style="width:408px"
            >
              <el-table-column prop="productName" label="类目"> </el-table-column>
              <el-table-column v-if="detail.orderType == 4" prop="expireYears" label="年数">
              </el-table-column>
              <el-table-column prop="productAmount" label="单价"> </el-table-column>
              <el-table-column prop="productCount" label="数量"> </el-table-column>
              <el-table-column prop="productSumAmount" label="总价"> </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="支付渠道：">
            <div>{{ payTypeName }}</div>
          </el-form-item>
          <el-form-item label="购买类型：">
            <div>{{ detail.payUser }}</div>
          </el-form-item>
          <el-form-item label="订单创建时间：">
            <div>{{ detail.createtime }}</div>
          </el-form-item>
          <el-form-item label="订单支付时间：">
            <div>{{ detail.payTime }}</div>
          </el-form-item>
        </el-form>
      </div>
      <div class="payPrice">
        <el-form ref="formDetail" :model="formDetail" label-width="130px">
          <el-form-item label="订单金额：" class="payPriceNews">
            <div class="money">￥{{ detail.orderAmount }}</div>
          </el-form-item>
          <el-form-item label="优惠金额：" class="payPriceNews">
            <div class="money">￥{{ detail.discountAmount }}</div>
          </el-form-item>
          <el-form-item label="订单实付金额：" class="payPriceNews">
            <div class="actualMoney">￥{{ detail.amount }}</div>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getOrderDetail } from '@/api/finance'
import to from 'await-to'
export default {
  data() {
    return {
      form: {},
      id: this.$route.query.id,
      detail: {},
      payTypeName: '',
      formDetail: {},
    }
  },
  created() {
    this.getOrderDetailData()
  },
  methods: {
    async getOrderDetailData() {
      const [res, err] = await to(getOrderDetail({ orderId: this.id }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.detail = res.data
      this.payTypeName = res.data.list[0].payTypeName
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      //   console.log(columns, data)
      var price = 0
      data.map(v => {
        price += Number(v.productSumAmount)
      })
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '小计'
          return
        }
        if (index === columns.length - 1) {
          sums[index] = price + '元'
          return
        }
      })

      return sums
    },
    goBack() {
      this.$router.push('orderList')
    },
    refund() {
      this.$message({ type: 'warning', message: '暂未开通此功能' })
    },
  },
}
</script>

<style scoped lang="scss">
.title {
  cursor: pointer;
}
.orderContent {
  padding-bottom: 20px;
  border-bottom: 1px solid #e2e2e2;
  ::v-deep .el-form-item {
    margin-bottom: 0;
  }
  .orderDetailTitle {
    background: #f6f6f6;
    line-height: 42px;
    font-size: 16px;
    color: #606266;
    padding-left: 20px;
  }
}
.payPrice {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20%;
  :last-child {
    &.payPriceNews {
      margin-top: 20px;
    }
  }
  .payPriceNews {
    .money {
      font-size: 16px;
      color: #ff7b33;
      margin-left: 24px;
    }
    .actualMoney {
      font-size: 20px;
      color: #ff7b33;
      margin-left: 24px;
    }
  }
}

.backBtn {
  display: inline-block;
  padding: 5px 12px;
  color: #fff;
  font-size: 14px;
  background-color: #ff7b33;
  margin-left: 20px;
  cursor: pointer;
}
.refundBtn {
  float: right;
  padding: 3px 0;
}
</style>
